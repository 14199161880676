import React, { createContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'src/components/seo';
import Layout from 'src/layouts/Layout';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import SocialMedia from 'src/components/SocialMedia/SocialMedia';
import {
  Section,
  MainSection,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import {
  HeaderParagraph,
  HeaderSection,
  HeaderTextWrapper,
  HeaderTitle,
} from 'src/components/Sites/ServicesPages/shared/Header';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import OurBenefitsSection from 'src/components/Sites/ServicesPages/WebPages/OurBenefitsSection';
import WhyItIsWorthSection from 'src/components/Sites/ServicesPages/WebPages/WhyItIsWorthSection';
import OurWebsitesStandOutSection from 'src/components/Sites/ServicesPages/WebPages/OurWebsitesStandOutSection';
import HowDoWeWorkSection from 'src/components/Sites/ServicesPages/WebPages/HowDoWeWorkSection';
import PagesWeMakeSection from 'src/components/Sites/ServicesPages/WebPages/PagesWeMakeSection';
import { FadeIn } from 'src/components/shared/Animations/Animations';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import useLng from "../hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";
import { CITIES } from '../components/lands';

export const WebsitesPageContext = createContext({});

const StyledImageWrapper = styled(ImageWrapper)`
  max-width: 50vw;
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledParagraphInfo = styled(ParagraphInfo)`
  font-size: ${({ small }) => (small ? '2rem' : '3rem')};
  line-height: 1.4;
  text-align: ${({ center }) => center && 'center'};
`;

const StyledSocialMedia = styled(SocialMedia)`
  position: absolute;
  left: 5rem;
  top: 7rem;
  padding: 4rem 7rem 0 0;

  @media screen and (max-width: 1199px) {
    left: 3rem;
  }

  @media screen and (max-width: 991px) {
    left: 2rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: 2.2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const Websites = ({
  location: { pathname: path },
  transitionStatus,
  entry,
  exit,
  data,
  ...rest
}) => {

  const headingImage = data?.allFile?.nodes?.find(
    ({ name }) => name === 'obraz z symbolem stron internetowych',
  ).childImageSharp.fluid ?? "";

  const { t } = useLng()
  const {language} = useI18next()


  const city = CITIES.find(el => el.city === rest.pageContext.city)

  return (
    <Layout
      path={path}
      id="strony-internetowe"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title= {`${rest.pageContext.city} - ${t("stronySeo")}`}
           description={`Profesjonalne tworzenie stron internetowych i aplikacji webowych w ${city.city2}. Agencja Makadu oferuje kompleksowe usługi projektowania responsywnych witryn, optymalizacji SEO oraz wsparcia technicznego w ${city.city2}. Zaufaj ekspertom z 15 letnim doświadczeniem. Nowoczesne rozwiązania, terminowa realizacja. Sprawdź nasze portfolio! `}
      />
      <Section nonGrid site first mb="0">
        <HeaderSection pl>
          <HeaderTextWrapper>
            <StyledSocialMedia />
            <div>
              <HeaderTitle small>
                {t("stronyInternetowe1")}
                <br/><span style={{color: "#faed2d", fontSize: "4rem", lineHeight: "1.5", marginTop: "3px"}}>{rest.pageContext.city}</span>
              </HeaderTitle>
              <h2 style={{fontSize: "2.5rem", letterSpacing: "0.5px", marginBottom:"1rem"}}>
                {t("strony2_c", {name: language === "pl" ? rest.pageContext.city2 : rest.pageContext.city })}
              </h2>
              <HeaderParagraph>
                {t("strony3")}
              </HeaderParagraph>
            </div>
          </HeaderTextWrapper>
          <StyledImageWrapper
            image={headingImage}
            alt="obraz z symbolem stron internetowych"
          />
        </HeaderSection>
      </Section>
      <WebsitesPageContext.Provider value={{data: data.allFile.nodes}}>
        <>
          <OurBenefitsSection data={data.allFile.nodes}/>
          <PagesWeMakeSection data={data.allFile.nodes} city={language === "pl" ? rest.pageContext.city2 : rest.pageContext.city}/>
          <HowDoWeWorkSection data={data.allFile.nodes} city={language === "pl" ? rest.pageContext.city2 : rest.pageContext.city}/>
          <WhyItIsWorthSection data={data.allFile.nodes} city={language === "pl" ? rest.pageContext.city2 : rest.pageContext.city}/>
          <OurWebsitesStandOutSection data={data.allFile.nodes}/>
        </>
      </WebsitesPageContext.Provider>
      <MainSection>
        <Wrapper nonGrid>
          <FadeIn>
            <Label>
              {rest.pageContext.city2 ? t("cennikTitle_c", {name: language === "pl" ? rest.pageContext.city2 : rest.pageContext.city}) : t("cennikTitle")}
              </Label>
          </FadeIn>
          <StyledParagraphInfo mb="4rem" small>
            <FadeIn>
              {t("cennik")}
            </FadeIn>
          </StyledParagraphInfo>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledFadeLink to="/projekty" stylish>
              {t("zobaczRealizacje")}
            </StyledFadeLink>
          </div>
        </Wrapper>
      </MainSection>
    </Layout>
  );
};

export const websitesQuery = graphql`
   {
    allFile(filter: { relativeDirectory: { eq: "websites-page" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default Websites;
